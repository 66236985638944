import React from "react";
import logo from "../components/logo.svg";
import hero_screen from "../pages/landing/hero+screen.png";
import { Link } from "gatsby";

function OmnidocAd(props) {
  const { small } = props;

  return (
    <Link to="/">
      <div className="flex flex-col p-8 w-full bg-action-100 rounded-lg">
        <div className="mb-4 mx-auto">
          <img src={logo} alt="Omnidoc Logo" />
        </div>
        <div
          className={`${
            small ? "font-semibold" : "text-xl font-bold"
          } text-action-800 text-center mb-4`}
        >
          La plateforme de téléexpertise qui facilite et rémunère la
          collaboration médicale
        </div>
        <div className="relative h-56">
          <img
            src={hero_screen}
            alt="Omnidoc, plateforme de télé-expertise sécurisée et rémunérée"
            className="absolute h-full w-full object-scale-down"
          />
        </div>
      </div>
    </Link>
  );
}

export { OmnidocAd };

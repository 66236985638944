import React from "react";
import { Link, graphql } from "gatsby";

import { Base } from "../components/Base";
import { Container } from "../components/Container";
import { OmnidocAd } from "../components/OmnidocAd";
import SEO from "../components/seo";

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const defaultCoverURL =
      this.props.data.defaultCover && this.props.data.defaultCover.publicURL;
    const coverPictureURL =
      (post.frontmatter.cover && post.frontmatter.cover.publicURL) ||
      defaultCoverURL;

    const { previous, next } = this.props.pageContext;
    const rootPath = `/actualites`;
    const { title, description, date } = post.frontmatter;

    return (
      <Base>
        <SEO
          title={title}
          description={description || post.excerpt}
          isArticle={true}
        />
        <Container>
          <Link className="block mt-8 text-action" to={rootPath}>
            ← Tous les articles
          </Link>
          <div className="max-w-4xl mx-auto">
            <article>
              <header className="my-8 sm:my-16 max-w-2xl mx-auto">
                <p className="text-lg text-gray-600 leading-loose">{date}</p>
                <h1 className="text-3xl sm:text-5xl font-bold">{title}</h1>
              </header>
              <div className="flex flex-col items-center bg-gray-200">
                <img
                  src={coverPictureURL}
                  className="h-112 w-full object-cover overflow-hidden"
                  alt={title}
                />
              </div>
              <section
                id="articleBody"
                className="markdown text-lg max-w-2xl mx-auto mt-16"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
              <div className="mb-4 max-w-2xl mx-auto">
                <OmnidocAd />
              </div>
              <hr className="h-px mb-8" />
            </article>

            <nav>
              <ul className="flex flex-wrap justify-between mb-8">
                <li>
                  {previous && (
                    <Link
                      className="text-action"
                      to={previous.fields.slug}
                      rel="prev"
                    >
                      ← {previous.frontmatter.title}
                    </Link>
                  )}
                </li>
                <li>
                  {next && (
                    <Link
                      className="text-action"
                      to={next.fields.slug}
                      rel="next"
                    >
                      {next.frontmatter.title} →
                    </Link>
                  )}
                </li>
              </ul>
            </nav>
          </div>
        </Container>
      </Base>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    defaultCover: file(
      relativePath: { regex: "/default_article_image.jpg/gm" }
    ) {
      publicURL
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "DD MMMM YYYY", locale: "fr")
        description
        cover {
          publicURL
        }
      }
    }
  }
`;
